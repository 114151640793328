/* Table.css */

.table {
  font-family: arial;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  table-layout: auto;
  border-collapse: collapse;
  box-shadow: 0px 1px 1px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 40em; /* Adjust the width value to make the table 200% */
  max-width: 80%;
  margin: auto;
  overflow-x: auto;
}

.table-wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.table thead {
  background-color: #ccc;
  color: #222;
}

.table th,
.table td {
  padding: 0.8rem;
}

.table td {
  border-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
}

.table-wrapper .table tbody tr:hover {
  background-color: lightblue;
}

.table-wrapper .table tbody tr:hover td {
  background-color: lightblue; /* Add this line */
}

.expand {
  width: 100%;
}

.label {
  border-radius: 3px;
  padding: 0.3rem;
  color: white;
}

.label-draft {
  background-color: #777;
}

.label-live {
  background-color: #42a942;
}

.label-error {
  background-color: #d9534f;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.actions svg {
  cursor: pointer;
}

.delete-btn {
  color: #e10d05;
}

.confirm-btn {
  color: #42a942;
}

.disregard-btn {
  color: #e10d05;
}



@media (max-width: 800px) {
  .table {
    width: 80%;
  }
}